import React, { useEffect } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { validateSlug } from 'utils/custom-validations';

const shortNameAndAttributeNameToSlug = (
  qualificationSlug: string,
  attributeName: string,
): string => {
  const val = (
    (qualificationSlug || '') +
    (attributeName?.length ? '_' : '') +
    (attributeName || '')
  )
    .toLowerCase()
    .split(' ')
    .join('_');
  return val;
};

const AttributeSlugInput = ({ attributeName, source }) => {
  const { setValue, watch } = useFormContext();
  const qualificationSlug = watch('slug');

  useEffect(() => {
    setValue(source, shortNameAndAttributeNameToSlug(qualificationSlug, attributeName));
  }, [qualificationSlug, attributeName]);

  return <TextInput label="Slug" source={source} validate={[required(), validateSlug]} />;
};

export default AttributeSlugInput;
