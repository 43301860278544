import { AxiosInstance } from 'axios';
import { getResourceUrl } from '../utils';
import config from 'config';

export default function remove(ajax: AxiosInstance) {
  return (resource, { id, company_id }) => {
    const baseUrl = getResourceUrl(resource, id);
    switch (resource) {
      case 'BillingContact':
      case 'Check':
      case 'ClinicalContact':
      case 'Credential':
      case 'CustomProfessionalProfileEhrSystem':
      case 'Education':
      case 'EmergencyContact':
      case 'ExpenseAdjustment':
      case 'InterviewAvailability':
      case 'Invoice':
      case 'ItemizedTransaction':
      case 'JobTemplate':
      case 'LeadPosition':
      case 'LeadSpecialty':
      case 'LicenseField':
      case 'LocationConnection':
      case 'LocationPosition':
      case 'LocationPositionRateSetting':
      case 'LocationTypePosition':
      case 'MarketPositionRateSetting':
      case 'MarketRateSetting':
      case 'Note':
      case 'PositionCertification':
      case 'PositionLicense':
      case 'PositionPostingField':
      case 'PositionSpecialty':
      case 'ProfessionalPosition':
      case 'ProfessionalProfileEhrSystem':
      case 'ProfessionalReference':
      case 'ProfessionalSpecialty':
      case 'Relationship':
      case 'Requirement':
      case 'RevenueAdjustment':
      case 'SkillsAssessmentResponse':
      case 'StatePositionRateSetting':
      case 'StateRateSetting':
      case 'UserPosition':
      case 'UserPositionLicense':
      case 'UserPositionRateSetting':
      case 'UserRateSetting':
      case 'WorkGap':
      case 'WorkHistory':
        return ajax({
          url: baseUrl,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((results) => {
          return {
            data: results.data,
          };
        });
      case 'ApprovalGroup':
        return ajax({
          url: `${config.rails_url}/v3/admin/companies/${company_id}/approval_groups/${id}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((results) => {
          return {
            data: results.data,
          };
        });
      case 'LocationGroup':
        return ajax({
          url: `${config.rails_url}/v3/admin/companies/${company_id}/group_nodes/${id}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((results) => {
          return {
            data: results.data,
          };
        });
      default:
        return Promise.reject(`Not allowed to delete resource ${resource}`);
    }
  };
}
