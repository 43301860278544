import React from 'react';
import {
  BooleanField,
  Datagrid,
  Pagination,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  NumberField,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TabbedShowLayoutTabs,
  SimpleShowLayout,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import Heading from 'components/Heading';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import useCheckAccess from 'hooks/useCheckAccess';
import JobAside from './Aside';

const JobBillingsShow = () => {
  const checkAccess = useCheckAccess();
  return (
    <Show aside={<JobAside />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Info">
          <SimpleShowLayout>
            <Heading>JobBilling Description</Heading>
            <TextField source="id" />
            <FunctionField
              label="Job ID"
              render={(v) =>
                v.job_id ? <Link to={`/Job/${v.job_id}/show`}>{v.job_id}</Link> : null
              }
            />
            <FunctionField
              label="Original Job Billing ID"
              render={(v) =>
                v.original_job_billing_id ? (
                  <Link to={`/JobBilling/${v.original_job_billing_id}/show`}>
                    {v.original_job_billing_id}
                  </Link>
                ) : null
              }
            />
            <FunctionField
              label="Location ID"
              render={(v) =>
                v.job?.location_id ? (
                  <Link to={`/location/${v.job.location_id}/show`}>{v.job.location_id}</Link>
                ) : null
              }
            />
            <DateTimeField
              format="MM/dd/yyyy"
              label="Starts Date"
              source="job.starts_date"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Starts Time"
              source="job.starts_time"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Ends Time"
              source="job.ends_time"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Make Chargeable At"
              source="make_chargeable_at"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Make Payable At"
              source="make_payable_at"
              timeZone="America/Los_Angeles"
            />
            <ArrayField label="Positions" source="job.positions">
              <SingleFieldList linkType={false}>
                <ChipField source="display_name" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField label="Specialties" source="job.specialties">
              <SingleFieldList linkType={false}>
                <ChipField source="label" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField label="Surgery Type" source="job.surgery_types">
              <SingleFieldList linkType={false}>
                <ChipField source="display_name" />
              </SingleFieldList>
            </ArrayField>
            <TextField label="Patient Population" source="job.patient_population" />
            <MoneyField label="Location Bill Rate" source="job.charge_base_hourly_rate_cents" />
            <MoneyField label="Professional Pay Rate" source="job.payout_base_hourly_rate_cents" />
            <MoneyField label="Bonus One Time Charge" source="job.bonus_one_time_cents" />
            <MoneyField label="Bonus One Time Payout" source="job.payout_bonus_cents" />
            <TextField label="Detail" source="job.detail" />
            <MoneyField label="Parking Reimbursement" source="job.parking_reimbursement_cents" />

            <Heading>JobBilling Status</Heading>
            <TextField label="Job's Current Status" source="job.status" />
            <DateTimeField label="Locked at" source="locked_at" timeZone="America/Los_Angeles" />
            <FunctionField
              label="Balance Sheet ID"
              render={(v) =>
                v.balance_sheet_id ? (
                  <Link to={`/BalanceSheet/${v.balance_sheet_id}/show`}>{v.balance_sheet_id}</Link>
                ) : null
              }
            />
            <TextField label="Job's Status at Locking" source="job_status" />
            <FunctionField
              label="Professional ID"
              render={(v) =>
                v.job?.professional_id ? (
                  <Link to={`/professional/${v.job.professional_id}/show`}>
                    {v.job.professional_id}
                  </Link>
                ) : null
              }
            />
            <DateTimeField
              label="Created at"
              source="created_at"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Booked at"
              source="job.booked_at"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Approved Clock in"
              source="job.approved_clock_in"
              timeZone="America/Los_Angeles"
            />
            <DateTimeField
              label="Approved Clock out"
              source="job.approved_clock_out"
              timeZone="America/Los_Angeles"
            />
            <BooleanField label="Canceled by Location" source="job.canceled_by_location" />
            <BooleanField label="Canceled by Professional" source="job.canceled_by_professional" />
            <BooleanField label="Extended by Location" source="job.extended_by_location" />
            <BooleanField label="Extended by Professional" source="job.extended_by_professional" />
            <BooleanField label="Professional can Cancel" source="job.professional_can_cancel" />
            <BooleanField
              label="Updated in Cancellation Window"
              source="job.updated_in_cancellation_window"
            />
            <BooleanField label="Suspicious" source="job.suspicious" />

            <Heading>Assignment</Heading>
            <TextField label="Assignment ID" source="job.assignment_id" />

            <Heading>For Admin</Heading>
            <TextField label="Admin Notes" source="job.admin_notes" />
          </SimpleShowLayout>
        </Tab>
        {checkAccess('view', 'Break') && (
          <Tab label="Breaks" path="breaks">
            <ReferenceManyField
              pagination={<Pagination />}
              reference="Break"
              label=""
              target="job_id"
              source="job_id"
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <DateTimeField source="starts_time" timeZone="America/Los_Angeles" />
                <NumberField source="duration_minutes" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

JobBillingsShow.query = gql`
  query GET_ONE($id: Int!) {
    jobBilling(id: $id) {
      job_id
      payout_id
      invoice_id
      id
      created_at
      past_month_adjustment
      total_hours
      total_on_call_hours
      payout_total_amount_cents
      payout_total_amount_currency
      charge_total_amount_cents
      charge_total_amount_currency
      payout_regular_hours
      payout_overtime_hours
      payout_double_hours
      charge_regular_hours
      charge_overtime_hours
      charge_double_hours
      payout_overtime_amount_cents
      charge_overtime_amount_cents
      payout_double_amount_cents
      charge_double_amount_cents
      charge_callback_hours
      charge_callback_amount_cents
      payout_callback_hours
      payout_callback_amount_cents
      payout_regular_amount_cents
      charge_regular_amount_cents
      total_breaks_minutes
      charge_total_amount_cents
      expense_adjustment_total_cents
      revenue_adjustment_total_cents
      make_chargeable_at
      make_payable_at
      balance_sheet_id
      job_status
      locked_at
      original_job_billing_id
      job {
        location_id
        suspicious
        breaks {
          id
          starts_time
          duration_minutes
        }
        positions {
          display_name
        }
        professional_id
        location {
          created_at
          timezone_lookup
        }
        specialties {
          id
          label
        }
        surgery_types {
          id
          display_name
        }
        assignment_id
        approved_clock_in
        approved_clock_out
        canceled_by_location
        charge_base_hourly_rate_cents
        payout_base_hourly_rate_cents
        bonus_one_time_cents
        payout_bonus_cents
        parking_reimbursement_cents
        canceled_by_professional
        extended_by_location
        extended_by_professional
        professional_can_cancel
        updated_in_cancellation_window
        detail
        booked_at
        patient_population
        status
        starts_time
        starts_date
        ends_time
        admin_notes
      }
    }
  }
`;

export default JobBillingsShow;
