import React from 'react';
import {
  TextField,
  NumberInput,
  SelectInput,
  BooleanInput,
  SimpleForm,
  useRecordContext,
  useResourceContext,
  Labeled,
} from 'react-admin';
import MoneyInput from 'components/MoneyInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import LocationInput from 'components/LocationInput';
import AgencyInput from 'components/AgencyInput';
import { isEmptyObject } from 'utils/object';
import W2And1099SubSettingsForm from './W2And1099SubSettingsForm';

const renderRateComponent = (isEdit, source) => {
  if (isEdit) {
    return (
      <Labeled>
        <TextField source={source} />
      </Labeled>
    );
  }

  if (source === 'market_id') {
    return <MarketInput source={source} />;
  } else if (source === 'state_id') {
    return <StateInput source={source} />;
  } else if (source === 'location_id') {
    return <LocationInput source={source} />;
  } else if (source === 'agency_id') {
    return (
      <>
        <LocationInput source="location_id" />
        <AgencyInput source="agency_id" />
      </>
    )
  }

  return null;
};

const getRateSource = (record, resource) => {
  if (record.market_id || resource === 'MarketRateSetting') {
    return 'market_id';
  } else if (record.state_id || resource === 'StateRateSetting') {
    return 'state_id';
  } else if (record.location_id || resource === 'LocationRateSetting' || resource === 'AgencyLocationRateSetting') {
    if (record.agency_id || resource === 'AgencyLocationRateSetting') {
      return 'agency_id';
    } else {
      return 'location_id';
    }
  }
  throw new Error('No rate representation found');
};

const RateSettingsForm = () => {
  const record = useRecordContext() ?? {};
  const resource = useResourceContext();
  let isEdit = true;
  if (isEmptyObject(record)) {
    isEdit = false;
  }
  const source = getRateSource(record, resource);

  return (
    <SimpleForm>
      {renderRateComponent(isEdit, source)}
      <BooleanInput label="Active" source="active" />
      <SelectInput
        label="Track Unpaid Breaks"
        source="settings.track_unpaid_breaks"
        choices={[
          { id: true, name: 'True' },
          { id: false, name: 'False' },
        ]}
      />
      <NumberInput label="VMS fee percent" source="settings.vms_fee_percent" />
      <NumberInput label="Agency VMS fee percent" source="settings.agency_vms_fee_percent" />
      <MoneyInput label="Compliance burden cents" source="settings.compliance_burden_cents" />
      <NumberInput label="Other burden multiplier" source="settings.other_burden_multiplier" />
      <NumberInput label="Guaranteed minimum hours" source="settings.guaranteed_minimum_hours" />
      <NumberInput
        label="Billable orientation hours"
        source="settings.billable_orientation_hours"
      />
      <NumberInput
        label="Medicare burden multiplier"
        source="settings.medicare_burden_multiplier"
      />
      <NumberInput
        label="Cancellation fee charge hours"
        source="settings.cancellation_fee_charge_hours"
      />
      <NumberInput
        label="Social security burden multiplier"
        source="settings.social_security_burden_multiplier"
      />
      <MoneyInput
        label="Federal unemployment max burden"
        source="settings.federal_unemployment_max_burden_cents"
      />
      <NumberInput
        label="Federal unemployment perfcent burden multiplier"
        source="settings.federal_unemployment_percent_burden_multiplier"
      />
      <MoneyInput
        label="State unemployment max burden"
        source="settings.state_unemployment_max_burden_cents"
      />
      <NumberInput
        label="Unemployment percent burden multiplier"
        source="settings.state_unemployment_percent_burden_multiplier"
      />
      <NumberInput
        label="Non billable orientation burden hours"
        source="settings.non_billable_orientation_burden_hours"
      />
      <MoneyInput label="RN minimum wage" source="settings.rn_minimum_wage_cents" />
      <MoneyInput label="LVN minimum wage" source="settings.lvn_minimum_wage_cents" />
      <MoneyInput label="Allied minimum wage" source="settings.allied_minimum_wage_cents" />
      <W2And1099SubSettingsForm source="settings" inheritedSettings={null} />
    </SimpleForm>
  );
};

export default RateSettingsForm;
