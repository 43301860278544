import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const buildAttributeOptions = (attribute) => {
  const { labels, values } = attribute.data ?? { labels: [], values: [] };
  return values.map((value, index) => ({ value, label: labels[index] }));
};

export const EnumerableMultiInput = ({ value = [], onChange, attribute }) => {
  const options = buildAttributeOptions(attribute);
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const newValue = typeof fieldValue === 'string' ? fieldValue.split(',') : fieldValue;
    onChange(newValue);
  };
  return (
    <FormControl fullWidth>
      <Select value={Array.isArray(value) ? value : []} multiple onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const EnumerableInput = ({ value = [], onChange, attribute }) => {
  const options = buildAttributeOptions(attribute);
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    onChange(fieldValue);
  };
  return (
    <FormControl fullWidth>
      <Select value={value} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
