import React from 'react';
import { Create, SimpleForm, DateInput } from 'react-admin';
import { endOfDay, startOfDay } from 'date-fns/fp';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useCheckAccess from 'hooks/useCheckAccess';
import ProfessionalInput from 'components/ProfessionalInput';

const defaultValues = {
  starts_time: startOfDay(new Date()),
  ends_time: endOfDay(new Date()),
  status: 'open',
};

const PayoutsCreate = () => {
  const checkAccess = useCheckAccess();
  return (
    <Create redirect="show">
      <SimpleForm defaultValues={defaultValues}>
        {checkAccess('create', 'Payout', 'professional_id') && <ProfessionalInput />}
        {checkAccess('create', 'Payout', 'status') && (
          <EnumSelectInput
            label="Status"
            source="status"
            filterOut={['approved', 'failed', 'in_transit', 'paid', 'pending']}
          />
        )}
        {checkAccess('create', 'Payout', 'starts_time') && (
          <DateInput label="Starts Date" source="starts_time" />
        )}
        {checkAccess('create', 'Payout', 'ends_time') && (
          <DateInput label="Ends Date" source="ends_time" />
        )}
      </SimpleForm>
    </Create>
  );
};

export default PayoutsCreate;
