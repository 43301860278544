import inflection from 'inflection';
import { ProfessionalAppStatusEnum } from 'typings/enums';
import { ENUMS } from './enums';

const nameMap: Map<string, string> = new Map([
  [`Job.status.${ENUMS.Job.status.late_cancellation}`, 'Canceled by staff'],
  [`Professional.application_status.${ProfessionalAppStatusEnum.q_and_a}`, 'Q and A'],
  [
    `Invoice.voucher_processing_status.${ENUMS.Invoice.voucher_processing_status.missing_timesheet}`,
    'Missing Time Sheet/Time Sheet Issue',
  ],
  [
    `Invoice.voucher_processing_status.${ENUMS.Invoice.voucher_processing_status.biweekly}`,
    'BiWeekly (Not this week)',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.VMS_FEE}`,
    'VMS fee',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.REBATE_PROMO_CREDIT}`,
    'Rebate  promo credit',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.REBATE_FOUR_HOUR_MINIMUM}`,
    'Rebate  4 hour minimum',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.REBATE_LATE_CANCELLATION}`,
    'Rebate  late cancellation',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.REBATE_BETTER_PRO_EXPERIENCE}`,
    'Rebate  better pro experience',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.HOURLY_PRO_INITIATED_ADJUSTMENT}`,
    'Hourly  pro initiated adjustment',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.HOURLY_FACILITY_INITIATED_ADJUSTMENT}`,
    'Hourly  facility initiated adjustment',
  ],
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.WRITE_OFF_RECOVERY}`,
    'Write-off recovery',
  ],
  [
    `FinancialTransaction.kind.${ENUMS.FinancialTransaction.kind.stripe_ach_in_app}`,
    'In-app ACH'
  ]
]);

export const getName = (resource, source, { id, name }) => {
  const key = `${resource}.${source}.${id}`;
  return nameMap.get(key) ?? inflection.humanize(name);
};
