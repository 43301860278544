import React from 'react';
import { gql } from '@apollo/client';
import {
  ArrayField,
  ChipField,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  BooleanInput,
  TextInput,
  NumberField,
  NumberInput,
  SingleFieldList,
  FunctionField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import List from 'components/List';
import { AdministratorFilter, IdFilter, SearchFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import DateInput from 'components/DateInput';
import { ENUMS } from 'components/Enum/enums';
import MoneyField from 'components/MoneyField';
import VmsTypeInput from 'components/VmsTypeInput';
import MspInput from 'components/MspInput';
import HealthSystemInput from 'components/HealthSystemInput';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';
import DateFilter from 'components/Filter/DateFilter';
import TravelProfileRemainingStepsField from 'components/TravelProfileRemainingStepsField';
import { AssignmentContractRange } from 'constants/assignment';

const AssignmentRequestsFilter = [
  <IdFilter />,
  <SearchFilter alwaysOn />,
  <ProfessionalInput source="search.professional_id" alwaysOn />,
  <TextInput label="Professional Name" source="search.professional_name" alwaysOn />,
  <AdministratorFilter
    label="Recruiter"
    alwaysOn
    resettable
    source="search.recruiter_id"
    filter={{
      permission_group_roles: [ENUMS.PermissionGroup.role.recruiter],
    }}
  />,
  <AdministratorFilter
    label="TAM"
    alwaysOn
    resettable
    source="search.cam_id"
    filter={{
      permission_group_roles: [ENUMS.PermissionGroup.role.sales_recruiter],
    }}
  />,
  <SelectInput
    alwaysOn
    choices={[
      { id: 'w2', name: 'W2' },
      { id: '1099', name: '1099' },
    ]}
    source="search.assignment_type"
    label="Assignment Type"
  />,
  <NumberInput label="Assignment ID" source="search.assignment_id" alwaysOn />,
  <EnumSelectInput label="Status" emptyText="All" alwaysOn source="status" />,
  <EnumSelectInput
    label="Assignment Status"
    emptyText="All"
    source="search.assignment_status"
    alwaysOn
  />,
  <EnumSelectInput
    label="VMS Profile Status"
    emptyText="All"
    alwaysOn
    source="vms_profile_status"
  />,
  <EnumSelectInput
    label="VMS Application Status"
    emptyText="All"
    alwaysOn
    source="vms_application_status"
  />,
  <PositionInput alwaysOn multiple source="search.position_ids" />,
  <DateInput alwaysOn label="VMS Profile Submitted At" source="search.vms_profile_submitted_at" />,
  <DateInput
    alwaysOn
    label="VMS Application Submitted At"
    source="search.vms_application_submitted_at"
  />,
  <DateFilter alwaysOn label="Applied on" source="search.created_at" />,
  <BooleanInput alwaysOn label="On Hold" source="search.on_hold" />,
  <VmsTypeInput alwaysOn label="VMS Type" source="search.vms_type_id" />,
  <MspInput alwaysOn label="MSP" source="search.msp_id" />,
  <HealthSystemInput alwaysOn label="Health System" source="search.health_system_id" />,
  <SelectInput
    alwaysOn
    choices={AssignmentContractRange}
    format={(choice) => choice?.label}
    label="Contract Value"
    optionText="label"
    parse={(id) => AssignmentContractRange.find((x) => x.id === id)?.value}
    source="search.contract_range"
  />,
];

const AssignmentRequestsList = () => (
  <List
    exporter={false}
    filters={AssignmentRequestsFilter}
    title="Requests"
    data-testid="assignment-request-list"
  >
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Pro ID" source="professional.id" />
      <TextField label="Pro Name" source="professional.account.name" />
      <ArrayField label="Position(s)" source="assignment.positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <NumberField label="Travel Profile %" source="travel_profile_strength" />
      <TravelProfileRemainingStepsField />
      <DateField label="Applied On Date" source="created_at" />
      <LinkField label="Assignment ID" source="assignment.id" />
      <FunctionField
        label="TAM"
        render={(v) => {
          const tam = v?.assignment?.recruiter;
          if (!tam) {
            return null;
          }
          return <LinkField to={`/Administrator/${tam.id}/show`}>{tam.account?.name}</LinkField>;
        }}
      />
      <TextField label="VMS Type" source="assignment.company.vms_type.label" />
      <TextField label="VMS Order #" source="assignment.order_number" />
      <TextField label="Health System" source="assignment.company.health_system.label" />
      <TextField label="Company Name" source="assignment.company.name" />
      <DateField label="VMS Profile Submitted At" source="vms_profile_submitted_at" />
      <DateField label="VMS Application Submitted At" source="vms_application_submitted_at" />
      <TextField label="Request Status" source="status" />
      <TextField label="VMS Profile" source="vms_profile_status" />
      <TextField label="VMS Application" source="vms_application_status" />
      <MoneyField label="TCV" source="assignment.charge_blended_hourly_rate_cents" />
      <DateTimeField label="Starts Date" format="MM/dd/yyyy" source="assignment.starts_date" />
    </Datagrid>
  </List>
);

AssignmentRequestsList.query = gql`
  query GET_LIST($input: AssignmentRequestQueryInput) {
    _assignmentRequestsMeta(input: $input) {
      count
    }
    assignmentRequests(input: $input) {
      id
      vms_profile_status
      vms_application_status
      vms_profile_submitted_at
      vms_application_submitted_at
      created_at
      status
      assignment_id
      assignment {
        id
        starts_date
        status
        is_w2
        order_number
        charge_blended_hourly_rate_cents
        positions {
          id
          display_name
        }
        company {
          id
          name
          vms_type {
            id
            label
          }
          health_system {
            id
            label
          }
          msp {
            id
            label
          }
        }
        primary_location {
          id
          timezone_lookup
          covid_requirement
        }
        number_of_openings
        recruiter {
          id
          account {
            name
          }
        }
      }
      professional_id
      professional {
        id
        account {
          id
          name
          first_name
          last_name
          phone_number
        }
        profile_strength
        recruiter_id
        recruiter {
          id
          account {
            id
            name
          }
        }
        markets {
          id
          name
          display_name
        }
      }
      offer_source
      phone_screening_request {
        id
        assignment_request {
          id
          professional_id
        }
        confirmed_phone_screening_time
        masked_professional_phone_number
        status
      }
      has_missing_work_history
      has_unexplained_work_gaps
      has_missing_education
      has_missing_ehr_software
      has_missing_references
      has_missing_availability
      has_missing_identity
      travel_profile_strength
    }
  }
`;

export default AssignmentRequestsList;
