import React, { Fragment, useState } from 'react';
import { useRecordContext } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { capitalize } from 'inflection';
import LinkField from 'components/LinkField';
import { formatDateTime } from 'components/DateTimeField';
import { DisplayHelpers } from 'utils/helpers';
import reverse from 'lodash/reverse';

const RateAudits = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  return !!record.audits ? (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        View Rates Change Log
      </Button>

      <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Rates Change Log</DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Field</TableCell>
                <TableCell>Original</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reverse([...record.audits]).map(
                ({ action, account, audited_changes, created_at, id, user_id }) => {
                  const isUpdate = action === 'update';
                  const [originalRecord, updatedRecord] = isUpdate
                    ? audited_changes.settings
                    : [audited_changes.settings];

                  const hasUpdatedChargeCents =
                    !isUpdate ||
                    `${updatedRecord?.charge_rate_cents}` !==
                      `${originalRecord?.charge_rate_cents}`;
                  const hasUpdatedMarginPercent =
                    !isUpdate ||
                    `${updatedRecord?.margin_percent_1099}` !==
                      `${originalRecord?.margin_percent_1099}`;

                  return (
                    <Fragment key={`audit-table-row-${id}`}>
                      {hasUpdatedChargeCents && (
                        <TableRow>
                          <TableCell>{capitalize(action)}</TableCell>
                          <TableCell>
                            {formatDateTime({ timeZone: 'America/Los_Angeles' })(
                              'MM/dd/yyyy HH:mm zzz',
                            )(created_at)}
                          </TableCell>
                          <TableCell>
                            {!!account && (
                              <LinkField to={`/Account/${user_id}/show`} href="_blank">
                                {account.name}
                              </LinkField>
                            )}
                          </TableCell>
                          <TableCell>charge_rate_cents</TableCell>
                          <TableCell title={originalRecord?.charge_rate_cents}>
                            {originalRecord?.charge_rate_cents &&
                              DisplayHelpers.money(originalRecord?.charge_rate_cents)}
                          </TableCell>
                          <TableCell title={updatedRecord?.charge_rate_cents}>
                            {updatedRecord?.charge_rate_cents &&
                              DisplayHelpers.money(updatedRecord?.charge_rate_cents)}
                          </TableCell>
                        </TableRow>
                      )}
                      {hasUpdatedMarginPercent && (
                        <TableRow>
                          <TableCell>{capitalize(action)}</TableCell>
                          <TableCell>
                            {formatDateTime({ timeZone: 'America/Los_Angeles' })(
                              'MM/dd/yyyy HH:mm zzz',
                            )(created_at)}
                          </TableCell>
                          <TableCell>
                            {!!account && (
                              <LinkField to={`/Account/${user_id}/show`} href="_blank">
                                {account.name}
                              </LinkField>
                            )}
                          </TableCell>
                          <TableCell>margin_percent_1099</TableCell>
                          <TableCell title={originalRecord?.margin_percent_1099}>
                            {originalRecord?.margin_percent_1099 &&
                              DisplayHelpers.percentage(originalRecord.margin_percent_1099)}
                          </TableCell>
                          <TableCell title={updatedRecord?.margin_percent_1099}>
                            {updatedRecord?.margin_percent_1099 &&
                              DisplayHelpers.percentage(updatedRecord?.margin_percent_1099)}
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                },
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default RateAudits;
