import { AxiosInstance } from 'axios';
import RequestError from '../RequestError';
import * as R from 'ramda';
import { getResourceUrl, unsetResourceRootKey } from 'providers/data_provider/utils';
import { defaultHeaders } from './defaults';
import { setResourceRootKey } from '../utils';
import config from 'config';
import _ from 'lodash';

export default function create(ajax: AxiosInstance) {
  return (resource, params: any, url?: string) => {
    switch (resource) {
      case 'Job': {
        const { data } = params;
        let url: string;
        let payload: any;
        if (data.assignment_id) {
          url = `${config.rails_url}/v3/admin/assignments/${data.assignment_id}/jobs`;
          payload = {
            job: _.omit(data, [
              'shift_type',
              'company_id',
              'company_id',
              'assignment_id',
              'create_multiple',
            ]),
          };
        } else {
          url = data.create_multiple
            ? `${getResourceUrl(resource)}/create_multiple`
            : getResourceUrl(resource);
          payload = {
            job: _.omit(data, ['shift_type', 'company_id', 'create_multiple']),
          };
        }
        return ajax({
          url,
          method: 'POST',
          baseURL: config.rails_url,
          data: payload,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
      }
      case 'LocationRateSetting': {
        const { data } = params;

        if (data.create_multiple) {
          return ajax({
            url: `${getResourceUrl(resource, data.id)}/enable_differential_recommendations`,
            method: 'POST',
            baseURL: config.rails_url,
            data: _.omit(data, ['create_multiple', 'id']),
            headers: defaultHeaders(),
          }).then(handleResponse(data, resource));
        }

        const singleCreateData = setResourceRootKey(resource, data);
        return ajax({
          url: getResourceUrl(resource),
          method: 'POST',
          baseURL: config.rails_url,
          data: singleCreateData,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
      }
      case 'Qualification': {
        const { data } = params;

        data?.qualification_attributes_attributes?.forEach((attr) => {
          attr.data = JSON.stringify(attr.data);
        });

        return ajax({
          url: url ?? getResourceUrl(resource),
          method: 'POST',
          baseURL: config.rails_url,
          data,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
      }
      case 'ProfessionalReference': {
        const { data } = params;
        if (data?.questions) {
          data.questions = JSON.stringify(data.questions);
        }

        return ajax({
          url: url ?? getResourceUrl(resource),
          method: 'POST',
          baseURL: config.rails_url,
          data,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
      }
      case 'Relationship': {
        const { data } = params;
        const payload = {
          mass_block: data.mass_block,
          relationship: _.omit(data, ['mass_block']),
        };

        return ajax({
          url: url ?? getResourceUrl(resource),
          method: 'POST',
          baseURL: config.rails_url,
          data: payload,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
      }
      default:
        const data = setResourceRootKey(resource, params.data);

        return ajax({
          url: url ?? getResourceUrl(resource),
          method: 'POST',
          baseURL: config.rails_url,
          data,
          headers: defaultHeaders(),
        }).then(handleResponse(data, resource));
    }
  };
}

const handleResponse = (data, resource) => (response) => {
  if (response.error || response.status >= 422) {
    return Promise.reject(new RequestError({ status: response.status, message: response.error }));
  }
  const payload = response.data.payload || response.data;
  const flattenedData = unsetResourceRootKey(resource, data);
  const result = R.mergeAll([flattenedData, payload]);
  if (result.id === undefined) {
    result.id = null;
  }

  return Promise.resolve({ data: result });
};
