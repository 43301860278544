import Heading from 'components/Heading';
import { titleize } from 'inflection';
import React from 'react';
import { BooleanInput, NumberInput } from 'react-admin';

const getInherited = (inheritedSettings: any, subSection: string, key: string) =>
  inheritedSettings && inheritedSettings[subSection]?.[key]
    ? String(inheritedSettings[subSection]?.[key])
    : null;

type Ten99SettingsProps = {
  inheritedSettings: any;
  source: string;
  bookableType: 'per_diem' | 'assignment';
};

const Ten99Settings = ({ inheritedSettings, source, bookableType }: Ten99SettingsProps) => (
  <>
    <Heading>1099 {titleize(bookableType)} Settings</Heading>
    <NumberInput
      source={`${source}.ten99_${bookableType}.charge_overtime_multiplier`}
      label="Charge Overtime Multiplier"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_overtime_multiplier',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.charge_double_overtime_multiplier`}
      label="Charge Double Overtime Multiplier"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_double_overtime_multiplier',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.charge_max_daily_regular_hours`}
      label="Charge Max Daily Regular Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_max_daily_regular_hours',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.charge_max_daily_overtime_hours`}
      label="Charge Max Daily Overtime Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_max_daily_overtime_hours',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.charge_max_weekly_regular_hours`}
      label="Charge Max Weekly Regular Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_max_weekly_regular_hours',
      )}
    />
    <BooleanInput
      source={`${source}.ten99_${bookableType}.charge_seventh_day_rule_enabled`}
      label="Charge Seventh Day Rule Enabled"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'charge_seventh_day_rule_enabled',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.payout_overtime_multiplier`}
      label="Payout Overtime Multiplier"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_overtime_multiplier',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.payout_double_overtime_multiplier`}
      label="Payout Double Overtime Multiplier"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_double_overtime_multiplier',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.payout_max_daily_regular_hours`}
      label="Payout Max Daily Regular Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_max_daily_regular_hours',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.payout_max_daily_overtime_hours`}
      label="Payout Max Daily Overtime Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_max_daily_overtime_hours',
      )}
    />
    <NumberInput
      source={`${source}.ten99_${bookableType}.payout_max_weekly_regular_hours`}
      label="Payout Max Weekly Regular Hours"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_max_weekly_regular_hours',
      )}
    />
    <BooleanInput
      source={`${source}.ten99_${bookableType}.payout_seventh_day_rule_enabled`}
      label="Payout Seventh Day Rule Enabled"
      helperText={getInherited(
        inheritedSettings,
        `ten99_${bookableType}`,
        'payout_seventh_day_rule_enabled',
      )}
    />
  </>
);

const W2And1099SubSettingsForm = ({
  source,
  inheritedSettings,
}: {
  source: string;
  inheritedSettings: any;
}) => {
  return (
    <>
      <Ten99Settings
        inheritedSettings={inheritedSettings}
        bookableType="per_diem"
        source={source}
      />
      <Ten99Settings
        inheritedSettings={inheritedSettings}
        bookableType="assignment"
        source={source}
      />

      <Heading>W2 Settings</Heading>
      <NumberInput
        source={`${source}.w2.charge_overtime_multiplier`}
        label="Charge Overtime Multiplier"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_overtime_multiplier')}
      />
      <NumberInput
        source={`${source}.w2.charge_double_overtime_multiplier`}
        label="Charge Double Overtime Multiplier"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_double_overtime_multiplier')}
      />
      <NumberInput
        source={`${source}.w2.charge_max_daily_regular_hours`}
        label="Charge Max Daily Regular Hours"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_max_daily_regular_hours')}
      />
      <NumberInput
        source={`${source}.w2.charge_max_daily_overtime_hours`}
        label="Charge Max Daily Overtime Hours"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_max_daily_overtime_hours')}
      />
      <NumberInput
        source={`${source}.w2.charge_max_weekly_regular_hours`}
        label="Charge Max Weekly Regular Hours"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_max_weekly_regular_hours')}
      />
      <BooleanInput
        source={`${source}.w2.charge_seventh_day_rule_enabled`}
        label="Charge Seventh Day Rule Enabled"
        helperText={getInherited(inheritedSettings, 'w2', 'charge_seventh_day_rule_enabled')}
      />
      <Heading>W2 Marketplace</Heading>
      <NumberInput
        source={`${source}.w2_per_diem.charge_overtime_multiplier`}
        label="Charge Overtime Multiplier"
        helperText={getInherited(inheritedSettings, 'w2_per_diem', 'charge_overtime_multiplier')}
      />
      <NumberInput
        source={`${source}.w2_per_diem.charge_double_overtime_multiplier`}
        label="Charge Double Overtime Multiplier"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'charge_double_overtime_multiplier',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.charge_max_daily_regular_hours`}
        label="Charge Max Daily Regular Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'charge_max_daily_regular_hours',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.charge_max_daily_overtime_hours`}
        label="Charge Max Daily Overtime Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'charge_max_daily_overtime_hours',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.charge_max_weekly_regular_hours`}
        label="Charge Max Weekly Regular Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'charge_max_weekly_regular_hours',
        )}
      />
      <BooleanInput
        source={`${source}.w2_per_diem.charge_seventh_day_rule_enabled`}
        label="Charge Seventh Day Rule Enabled"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'charge_seventh_day_rule_enabled',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.payout_overtime_multiplier`}
        label="Payout Overtime Multiplier"
        helperText={getInherited(inheritedSettings, 'w2_per_diem', 'payout_overtime_multiplier')}
      />
      <NumberInput
        source={`${source}.w2_per_diem.payout_double_overtime_multiplier`}
        label="Payout Double Overtime Multiplier"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'payout_double_overtime_multiplier',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.payout_max_daily_regular_hours`}
        label="Payout Max Daily Regular Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'payout_max_daily_regular_hours',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.payout_max_daily_overtime_hours`}
        label="Payout Max Daily Overtime Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'payout_max_daily_overtime_hours',
        )}
      />
      <NumberInput
        source={`${source}.w2_per_diem.payout_max_weekly_regular_hours`}
        label="Payout Max Weekly Regular Hours"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'payout_max_weekly_regular_hours',
        )}
      />
      <BooleanInput
        source={`${source}.w2_per_diem.payout_seventh_day_rule_enabled`}
        label="Payout Seventh Day Rule Enabled"
        helperText={getInherited(
          inheritedSettings,
          'w2_per_diem',
          'payout_seventh_day_rule_enabled',
        )}
      />
    </>
  );
};

export default W2And1099SubSettingsForm;
