import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  BooleanField,
  useRecordContext,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const AgenciesTab = () => {
  const record = useRecordContext();
  console.log('record', record);
  return (
    <SimpleShowLayout>
      <>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="AgencyLocation"
          target="location_id"
        >
          <Datagrid bulkActionButtons={false}>
            <LinkField label="ID" source="id" typename="AgencyLocation" />
            <TextField label="Agency Location Status" source="status" />
            <LinkField label="Name" source="agency.name" />
            <TextField label="Agency Status" source="agency.status" />
            <BooleanField
              label="Auto Publish Per Diem"
              source="auto_publish_per_diem_to_professionals"
            />
            <BooleanField
              label="Auto Publish Assignments"
              source="auto_publish_assignments_to_professionals"
            />
          </Datagrid>
        </ReferenceManyField>
      </>
      <Button
        component={Link}
        to="/AgencyLocation/create"
        state={{ record: { location_id: record.id } }}
        variant="contained"
        size="small"
        sx={{ width: 205, marginTop: '16px' }}
      >
        Add Agency
      </Button>
    </SimpleShowLayout>
  );
};

export default AgenciesTab;
